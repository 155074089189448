import { api } from '@/lib/api'

import { MetaPaginate } from '../types'

export interface ProjectKind {
  id: string
  name: string
}

export interface ProjectStatus {
  id: string
  label: string
  order: number
  project_kind: ProjectKind
}

export interface User {
  id: string
  name: string
  email: string
}

export interface Project {
  id: string
  code: string
  done_deal?: boolean
  kind: ProjectKind
  title: string
  status: ProjectStatus
  user: User
  total_images: number
  created_at: string
  need_image_approval: boolean
}

export interface ProjectsOutput {
  data: Project[]
  meta: MetaPaginate
}

export interface PaginateOptions {
  page: number
  per_page?: number
}

export async function getProjects(
  sortOptions: Record<string, 'asc' | 'desc' | null> = {},
  filterOptions: Record<string, any> = {},
  paginationOptions: PaginateOptions,
): Promise<ProjectsOutput> {
  const { page = 1 } = paginationOptions

  const fields = Object.keys(sortOptions)
  const sortQuery = {}

  for (const field of fields) {
    if (sortOptions[field]) {
      Object.assign(sortQuery, { [`${field}_order`]: sortOptions[field] })
    }
  }

  const result = await api.get<ProjectsOutput>('/projects', {
    params: { ...sortQuery, ...filterOptions, page },
  })

  return result.data || []
}
