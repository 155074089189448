import { produce } from 'immer'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { CreteUpdateProjectKindModalType, ModalType } from './types'

interface ProjectKindStoreState {
  modals: {
    createUpdateProjectKind: {
      status: boolean
      payload?: CreteUpdateProjectKindModalType['payload'] | null
    }
  }
}

interface ProjectKindStoreActions {
  setModalStatus: (data: ModalType, status: boolean) => void
}

interface ProjectKindStore {
  state: ProjectKindStoreState
  actions: ProjectKindStoreActions
}

const INITIAL_STATE: ProjectKindStoreState = {
  modals: {
    createUpdateProjectKind: { status: false, payload: null },
  },
}

export const useProjectKindStore = create<ProjectKindStore>()(
  devtools((set) => {
    const setState = (fn: any) => set(produce<ProjectKindStore>(fn))

    return {
      state: INITIAL_STATE,
      actions: {
        setModalStatus: (data, status) => {
          setState(({ state }: ProjectKindStore) => {
            let payloadLocal: any = null

            if ('payload' in data) {
              payloadLocal = data.payload
            }

            const { name } = data
            state.modals[name] = {
              status,
              payload: payloadLocal || null,
            }
          })
        },
      },
    }
  }),
)
