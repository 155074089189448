import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { format, parseISO } from 'date-fns'
import {
  Banknote,
  Check,
  EllipsisVertical,
  ListChecks,
  Pencil,
} from 'lucide-react'
import { toast } from 'sonner'

import { LoadingButton } from '@/components/Form/button'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx'
import { Form } from '@/components/ui/form'
import { GET_PROJECT_DETAILS } from '@/lib/react-query/keys'
import { ProjectKind } from '@/lib/requests/project-kinds/get_project_kinds'
import {
  CreateProjectRequest,
  createProjects,
} from '@/lib/requests/projects/create-project'
import { ProjectDetails } from '@/lib/requests/projects/get-project-by-id'
import {
  ProjectImageStatus,
  updateProject,
} from '@/lib/requests/projects/update-project'
import { uploadFileToS3 } from '@/lib/requests/uploads/upload-file-to-s3'
import { UserOption } from '@/lib/requests/users/get-users'
import { Briefing } from '@/pages/Projects/components/Form/components/briefing.tsx'
import { CamposCustomizados } from '@/pages/Projects/components/Form/components/campos-customizados.tsx'
import { Contrato } from '@/pages/Projects/components/Form/components/contrato.tsx'
import { Dados } from '@/pages/Projects/components/Form/components/dados.tsx'
import { EntregaFinal } from '@/pages/Projects/components/Form/components/entrega-final.tsx'
import { Imagens } from '@/pages/Projects/components/Form/components/imagens.tsx'
import { Informacoes } from '@/pages/Projects/components/Form/components/informacoes.tsx'
import { Orcamento } from '@/pages/Projects/components/Form/components/orcamento.tsx'
import { ProjectBudgetFormModal } from '@/pages/Projects/components/ProjectBudgetFormModal'
import { ProjectBudgetsModal } from '@/pages/Projects/components/ProjectBudgetsModal'
import { useAppStore } from '@/store/app.store'
import { useProjectStore } from '@/store/project/store'

import { ProjectFinancialFormModal } from '../ProjectFinancialFormModal'
import { ProjectFinancialModal } from '../ProjectFinancialModal'
import { TimelineDrawer } from '../TimelineDrawer'

import { FormTitle } from './components/form-title'
import { FormSchema, IFormSchema } from './validate'

interface ProjectFormProps {
  projectId?: string
  initialData?: ProjectDetails
  users: UserOption[]
  projectKinds: ProjectKind[]
  readonly?: boolean
}

export function ProjectBaseForm({
  projectId,
  initialData,
  users,
  projectKinds,
  readonly = false,
}: ProjectFormProps) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const isAdmin = useAppStore((store) => store.state.isAdmin)
  const setModalStatus = useProjectStore(
    (store) => store.actions.setModalStatus,
  )
  const projectFinancialFormModal = useProjectStore(
    (store) => store.state.modals.financialForm,
  )
  const projectFinancialModal = useProjectStore(
    (store) => store.state.modals.financial,
  )
  const projectBudgetsModal = useProjectStore(
    (store) => store.state.modals.budgets,
  )
  const projectBudgetFormModal = useProjectStore(
    (store) => store.state.modals.budgetForm,
  )

  const isNewProject = useMemo(() => {
    return !projectId
  }, [projectId])

  const [timelineDrawerIsOpened, setTimelineDrawerIsOpened] = useState(false)

  const form = useForm<IFormSchema>({
    resolver: zodResolver(FormSchema),
    defaultValues: async () => {
      if (initialData) {
        return {
          finished_at: initialData.finished_at
            ? parseISO(initialData.finished_at)
            : null,

          signed_contract_at: initialData.signed_contract_at
            ? parseISO(initialData.signed_contract_at)
            : null,

          done_deal_at: initialData.done_deal_at
            ? parseISO(initialData.done_deal_at)
            : null,

          project_kind: initialData.kind.id,
          done_deal: !!initialData.done_deal,
          title: initialData.title,
          user_id: initialData.user.id,
          briefing_url: initialData.briefing_url || '',
          delivery_term_url: initialData.delivery_term_url || '',
          delivered_docs_url: initialData.delivered_docs_url || undefined,
          received_docs_url: initialData.received_docs_url || undefined,
          contract_url: initialData.contract_url || '',
          accepted_budget_value: initialData.accepted_budget?.value || 0,
          accepted_budget_date: initialData.accepted_budget?.date
            ? parseISO(initialData.accepted_budget.date)
            : null,
          project_fields_attributes: initialData.project_fields.map(
            (field) => ({
              kind: field.kind,
              name: field.name,
              show_to_client: field.show_to_client,
              value: Number.isNaN(Number(field.value))
                ? String(field.value)
                : Number(field.value),
              _destroy: false,
              itemId: field.id,
            }),
          ),
          project_images_attributes: initialData?.project_images?.map(
            (item) => {
              return {
                url: item?.url || '',
                itemId: item?.id || '',
                title: item?.title || '',
                status: item?.status || ProjectImageStatus.awaiting,
                file: undefined,
                comment: item?.comment || '',
                _destroy: false,
              }
            },
          ),
        }
      }
      return {
        project_kind: '',
        finished_at: null,
        done_deal_at: null,
        signed_contract_at: null,
        user_id: '',
        contract_url: '',
        briefing_url: '',
        delivery_term_url: '',
        title: '',
        delivered_docs_url: '',
        received_docs_url: '',
        project_images_attributes: [],
        project_fields_attributes: [],
        accepted_budget_date: null,
        accepted_budget_value: 0,
      }
    },
  })

  const createOrUpdateProjectMutation = useMutation({
    mutationFn: async (data: IFormSchema) => {
      // Remove da requisição os campos que são somente exibicao
      delete data.accepted_budget_date
      delete data.accepted_budget_value

      if (readonly) return

      const fileKeys: {
        fileKey: keyof IFormSchema
        urlKey: keyof IFormSchema
      }[] = [
        { fileKey: 'contract_file', urlKey: 'contract_url' },
        { fileKey: 'briefing_file', urlKey: 'briefing_url' },
        { fileKey: 'delivery_term_file', urlKey: 'delivery_term_url' },
      ]

      for (const key of fileKeys) {
        if (data[key.fileKey] instanceof File) {
          const url = await uploadFileToS3(data[key.fileKey] as File)
          delete data[key.fileKey]
          Object.assign(data, { [key.urlKey]: url })
        }
      }

      const filteredImages = data.project_images_attributes.filter(
        (img) => img.itemId || img.file || img.url,
      )

      const filteredFields = data.project_fields_attributes.filter((item) => {
        if (item._destroy && !item.itemId) return false
        return true
      })

      for (const image of filteredImages) {
        if (image.file && image.file instanceof File) {
          const url = await uploadFileToS3(image.file)
          delete image.file
          Object.assign(image, { url })
        }
      }

      for (const field of filteredFields) {
        if (field.kind === 'field_file' && field.file instanceof File) {
          const url = await uploadFileToS3(field.file)
          delete field.file
          Object.assign(field, { value: url })
        }
      }

      const payload: CreateProjectRequest = {
        project_kind_id: data.project_kind,
        title: data.title,
        delivered_docs_url: data.delivered_docs_url,
        received_docs_url: data.received_docs_url,
        briefing_url: data.briefing_url,
        contract_url: data.contract_url,
        delivery_term_url: data.delivery_term_url,
        user_id: data.user_id,
        project_fields_attributes: filteredFields.map((field, index) => ({
          id: field.itemId || '',
          kind: field.kind,
          name: field.name,
          value: String(field.value),
          show_to_client: field.show_to_client,
          _destroy: field._destroy || false,
          order: index + 1,
        })),
        project_images_attributes: filteredImages.map((item) => ({
          url: item.url,
          title: item.title,
          id: item.itemId,
          status: item.status,
          _destroy: item._destroy || false,
        })),
      }

      if (isAdmin) {
        Object.assign(payload, {
          done_deal: data.done_deal,
          done_deal_at: data.done_deal_at
            ? format(data.done_deal_at, 'yyyy-MM-dd')
            : null,
          finished_at: data.finished_at
            ? format(data.finished_at, 'yyyy-MM-dd')
            : null,
          signed_contract_at: data.signed_contract_at
            ? format(data.signed_contract_at, 'yyyy-MM-dd')
            : null,
        })
      }

      if (projectId) {
        return await updateProject(projectId, payload)
      } else {
        return await createProjects({
          ...payload,
          title: payload.title,
          user_id: payload.user_id,
        })
      }
    },
    onSuccess: async () => {
      toast.success('Projeto salvo com sucesso')
      if (isNewProject) {
        navigate('/')
      }
      await queryClient.invalidateQueries({
        queryKey: [GET_PROJECT_DETAILS],
        exact: false,
      })
    },
  })

  const onSubmit = useCallback(
    (data: IFormSchema) => {
      if (readonly) return
      createOrUpdateProjectMutation.mutate(data)
    },
    [readonly, createOrUpdateProjectMutation],
  )

  const openProjectFinancialModal = useCallback(
    (itemId: string) => {
      setModalStatus(
        { name: 'financial', payload: { projectId: itemId } },
        true,
      )
    },
    [setModalStatus],
  )

  const showImagesSection = useMemo(() => {
    if (!readonly) return true
    return (initialData?.project_images?.length || 0) > 0
  }, [initialData?.project_images, readonly])

  const projectMenuItems = useMemo(() => {
    if (!initialData) return []
    if (!initialData?.id) return []

    const adminItems = [
      {
        label: 'Editar',
        icon: <Pencil className="mr-2 h-4 w-4" />,
        onClick: () => {
          navigate(`/projetos/${initialData.id}/editar`)
        },
      },
      {
        label: 'Orçamentos',
        icon: <Check className="mr-2 h-4 w-4" />,
        onClick: () => {
          setModalStatus(
            {
              name: 'budgets',
              payload: { projectId: initialData.id },
            },
            true,
          )
        },
      },
    ]
    const defaultItems = [
      {
        label: 'Linha do projeto',
        icon: <ListChecks className="mr-2 h-4 w-4" />,
        onClick: () => {
          setTimelineDrawerIsOpened(true)
        },
      },
      {
        label: 'Financeiro',
        icon: <Banknote className="mr-2 h-4 w-4" />,
        onClick: () => {
          openProjectFinancialModal(initialData.id)
        },
      },
    ]

    if (!isAdmin) return defaultItems
    return [...adminItems, ...defaultItems]
  }, [
    initialData,
    isAdmin,
    navigate,
    openProjectFinancialModal,
    setModalStatus,
  ])

  return (
    <div className="mb-5 w-full max-w-6xl px-8">
      <Form {...form}>
        <div className="flex w-full items-center justify-between">
          <FormTitle
            details={initialData}
            isNewProject={isNewProject}
            readonly={readonly}
          />

          {readonly && initialData && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon">
                  <EllipsisVertical />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Ações</DropdownMenuLabel>
                <DropdownMenuSeparator />
                {projectMenuItems.map((menuItem) => (
                  <DropdownMenuItem
                    key={menuItem.label}
                    onClick={menuItem.onClick}
                  >
                    {menuItem.icon}
                    <span>{menuItem.label}</span>
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>

        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <>
            <Dados
              readonly={readonly}
              users={users}
              projectKinds={projectKinds}
              initialData={initialData || null}
            />
            <Orcamento readonly={readonly} initialData={initialData || null} />
            <Contrato readonly={readonly} />
            <Briefing readonly={readonly} />

            {showImagesSection && <Imagens readonly={readonly} />}

            <Informacoes readonly={readonly} />

            {!readonly && <CamposCustomizados readonly={readonly} />}

            <EntregaFinal readonly={readonly} />
          </>
          {isAdmin && (
            <div className={'flex w-full justify-end'}>
              <div>
                <LoadingButton
                  isLoading={createOrUpdateProjectMutation.isPending}
                >
                  <span>Salvar</span>
                </LoadingButton>
              </div>
            </div>
          )}
        </form>
      </Form>

      {projectFinancialModal.status && <ProjectFinancialModal />}
      {isAdmin && projectFinancialFormModal.status && (
        <ProjectFinancialFormModal />
      )}
      {isAdmin && projectBudgetsModal.status && <ProjectBudgetsModal />}
      {isAdmin && projectBudgetFormModal.status && <ProjectBudgetFormModal />}
      {readonly && (
        <TimelineDrawer
          close={() => setTimelineDrawerIsOpened(false)}
          isOpen={timelineDrawerIsOpened}
          timelineData={initialData?.project_history || []}
          projectKindId={initialData?.kind.id || ''}
        />
      )}
    </div>
  )
}
