import { api } from '@/lib/api'

interface CreateUserRequest {
  name: string
  email: string
}

export function createUser(payload: CreateUserRequest) {
  return api.post('/users', payload)
}
