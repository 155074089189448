import { api } from '@/lib/api'

export interface CreateProjectRequest {
  project_kind_id: string
  user_id: string
  title: string
  received_docs_url?: string | null
  delivered_docs_url?: string | null
  signed_contract_at?: string | null
  finished_at?: string | null
  done_deal_at?: string | null
  done_deal?: boolean
  contract_url?: string | null
  briefing_url?: string | null
  delivery_term_url?: string | null
  project_images_attributes?: {
    url: string
    title: string
    id?: string
  }[]
  project_fields_attributes?: {
    id: string
    kind: string
    name: string
    value: string | null
    show_to_client: boolean
  }[]
}

export async function createProjects(data: CreateProjectRequest) {
  await api.post('/projects', data)
}
