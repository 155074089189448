import { useCallback, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Loader2 } from 'lucide-react'
import { toast } from 'sonner'
import { z } from 'zod'

import { RadioGroupFormField } from '@/components/Form/radio-group'
import { TextareaField } from '@/components/Form/textarea'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Form } from '@/components/ui/form'
import { UploadFileInput } from '@/components/upload-file'
import { GET_PROJECT_DETAILS, GET_PROJECTS } from '@/lib/react-query/keys'
import {
  getProjectById,
  ProjectDetails,
  ProjectImageStatus,
} from '@/lib/requests/projects/get-project-by-id'
import { updateProject } from '@/lib/requests/projects/update-project'
import { useProjectStore } from '@/store/project/store'

const FormSchema = z.object({
  projectTestImages: z.array(
    z.object({
      itemId: z.string().optional(),
      title: z.string(),
      status: z.nativeEnum(ProjectImageStatus, {
        errorMap: () => {
          return { message: 'Campo inválido' }
        },
      }),
      url: z.string({ required_error: 'Campo obrigatório' }),
      comment: z.string().optional(),
    }),
  ),
})

type IFormSchema = z.infer<typeof FormSchema>

interface ChangeStatusFormProps {
  project: ProjectDetails
}

function ImagesReviewForm({ project }: ChangeStatusFormProps) {
  const queryClient = useQueryClient()
  const testImagesReviewModal = useProjectStore(
    (store) => store.state.modals.imageReview,
  )
  const setModalStatus = useProjectStore(
    (store) => store.actions.setModalStatus,
  )

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      projectTestImages: project.project_images.map((img) => ({
        comment: img.comment || undefined,
        title: img.title,
        itemId: img.id,
        status: img.status as any,
        url: img.url,
      })),
    },
  })

  const { fields } = useFieldArray({
    control: form.control,
    name: 'projectTestImages',
  })

  const onClose = useCallback(() => {
    setModalStatus({ name: 'imageReview' }, false)
  }, [setModalStatus])

  const testImagesReviewMutation = useMutation({
    mutationFn: async (data: IFormSchema) => {
      if (!project.id) return

      await updateProject(project.id, {
        project_images_attributes: data.projectTestImages.map((img) => ({
          status: img.status,
          title: img.title,
          url: img.url,
          id: img.itemId,
          comment: img.comment,
          _destroy: false,
        })),
      })
    },
    onSuccess: async () => {
      toast.success('Projeto atualizado com sucesso')
      await queryClient.invalidateQueries({
        queryKey: [GET_PROJECTS],
        exact: false,
      })
      onClose()
    },
  })

  const onSubmit = useCallback(() => {
    setShowConfirmDialog(true)
  }, [])

  return (
    <>
      <Dialog open={testImagesReviewModal.status} onOpenChange={onClose}>
        <DialogContent className="max-h-[90%] overflow-x-auto sm:max-w-[625px]">
          <DialogHeader>
            <DialogTitle>Imagens</DialogTitle>
          </DialogHeader>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="w-full space-y-6"
            >
              {fields.map((item, index) => (
                <div key={item.id}>
                  <div className="flex items-start space-x-4">
                    <UploadFileInput<IFormSchema>
                      readonly
                      label={item.title}
                      name={`projectTestImages.${index}.url`}
                      url={item.url}
                    />
                    <TextareaField<IFormSchema>
                      label="Observação sobre a imagem"
                      inputProps={{
                        className: 'h-40 resize-none',
                        placeholder: 'A imagem...',
                        disabled:
                          project.project_images[index].status !== 'awaiting',
                      }}
                      name={`projectTestImages.${index}.comment`}
                    />
                  </div>

                  <RadioGroupFormField<IFormSchema>
                    name={`projectTestImages.${index}.status`}
                    disabled={
                      project.project_images[index].status !==
                      ProjectImageStatus.awaiting
                    }
                    options={[
                      {
                        label: 'Aprovado',
                        value: ProjectImageStatus.approved,
                      },
                      {
                        label: 'Reprovar',
                        value: ProjectImageStatus.rejected,
                      },
                    ]}
                  />
                </div>
              ))}

              <Button type="submit">
                <span>Salvar</span>
              </Button>

              <AlertDialog open={showConfirmDialog}>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>
                      Enviar revisão de imagens
                    </AlertDialogTitle>
                    <AlertDialogDescription>
                      Tem certeza que deseja enviar a revisão de imagens ?
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel
                      onClick={() => setShowConfirmDialog(false)}
                    >
                      Cancelar
                    </AlertDialogCancel>
                    <AlertDialogAction
                      type="button"
                      onClick={() => {
                        const values = form.getValues()
                        testImagesReviewMutation.mutate(values)
                      }}
                    >
                      {testImagesReviewMutation.isPending ? (
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      ) : (
                        <span>Enviar</span>
                      )}
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  )
}

export function ImagesReviewModal() {
  const modalPayload = useProjectStore(
    (store) => store.state.modals.imageReview.payload,
  )

  const projectId = modalPayload?.projectId || ''

  const { data, isFetching } = useQuery({
    queryKey: [GET_PROJECT_DETAILS, projectId],
    queryFn: () => getProjectById(projectId || ''),
    enabled: !!projectId,
  })

  if (!data || isFetching) return null

  return <ImagesReviewForm project={data} />
}
