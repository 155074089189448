import { produce } from 'immer'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import {
  ImageReviewModalType,
  ModalType,
  ProjectBudgetFormModalType,
  ProjectBudgetsModalType,
  ProjectFinancialFormModalType,
  ProjectFinancialModalType,
  UpdateStatusModalType,
} from './types'

interface ProjectStoreState {
  modals: {
    filters: { status: boolean }
    updateStatus: {
      status: boolean
      payload?: UpdateStatusModalType['payload'] | null
    }
    financial: {
      status: boolean
      payload?: ProjectFinancialModalType['payload'] | null
    }
    financialForm: {
      status: boolean
      payload?: ProjectFinancialFormModalType['payload'] | null
    }
    budgets: {
      status: boolean
      payload?: ProjectBudgetsModalType['payload'] | null
    }
    budgetForm: {
      status: boolean
      payload?: ProjectBudgetFormModalType['payload'] | null
    }
    imageReview: {
      status: boolean
      payload?: ImageReviewModalType['payload'] | null
    }
  }
}

interface ProjectStoreActions {
  setModalStatus: (data: ModalType, status: boolean) => void
}

interface ProjectStore {
  state: ProjectStoreState
  actions: ProjectStoreActions
}

const INITIAL_STATE: ProjectStoreState = {
  modals: {
    filters: { status: false },
    updateStatus: { status: false, payload: null },
    financial: { status: false, payload: null },
    financialForm: { status: false, payload: null },
    imageReview: { status: false },
    budgets: { status: false, payload: null },
    budgetForm: { status: false, payload: null },
  },
}

export const useProjectStore = create<ProjectStore>()(
  devtools((set) => {
    const setState = (fn: any) => set(produce<ProjectStore>(fn))

    return {
      state: INITIAL_STATE,
      actions: {
        setModalStatus: (data, status) => {
          setState(({ state }: ProjectStore) => {
            let payloadLocal: any = null

            if ('payload' in data) {
              payloadLocal = data.payload
            }

            const { name } = data
            state.modals[name] = {
              status,
              payload: payloadLocal || null,
            }
          })
        },
      },
    }
  }),
)
