import { z } from 'zod'

import { ProjectImageStatus } from '@/lib/requests/projects/update-project'
import {
  validateFileSize,
  validateFileType,
} from '@/lib/zod-validators/validate-file'

export const ProjectFieldsAttributesSchema = z.object({
  _destroy: z.boolean().default(false).optional(),
  itemId: z.string().optional(),
  kind: z.string({ required_error: 'Campo obrigatório' }),
  name: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Campo obrigatório'),
  value: z.string().or(z.number()).nullable(),
  file: z
    .any()
    .optional()
    .transform((file) => file || undefined),
  show_to_client: z
    .boolean({ required_error: 'Campo obrigatório' })
    .default(false),
})

const ProjectTestImagesAttributesSchema = z
  .object({
    itemId: z.string().optional(),
    title: z.string({ required_error: 'Campo obrigatório' }),
    status: z.nativeEnum(ProjectImageStatus, {
      required_error: 'Campo obrigatório',
    }),
    comment: z.string().optional(),
    url: z.string({ required_error: 'Campo obrigatório' }),
    _destroy: z.boolean().default(false).optional(),
    file: z
      .any()
      .optional()
      .transform((file) => file || undefined)
      .refine(validateFileSize.check, validateFileSize.opts)
      .refine(validateFileType.check, validateFileType.opts),
  })
  .refine(
    (values) => {
      if ((values.url || values.file) && !values.title) return false
      return true
    },
    { message: 'Campo obrigatório', path: ['title'] },
  )

export const FormSchema = z.object({
  project_kind: z.string().min(1, 'Campo obrigatório'),
  finished_at: z.date().nullable().default(null),
  done_deal_at: z.date().nullable().default(null),
  signed_contract_at: z.date().nullable().default(null),
  done_deal: z.boolean().optional(),
  user_id: z
    .string({
      required_error: 'Campo obrigatório',
    })
    .min(1, 'Campo obrigatório'),
  received_docs_url: z.string().optional(),
  title: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Campo obrigatório'),
  delivered_docs_url: z.string().optional(),
  contract_file: z
    .any()
    .optional()
    .transform((file) => {
      return file || undefined
    })
    .refine(validateFileSize.check, validateFileSize.opts)
    .refine(validateFileType.check, validateFileType.opts),
  contract_url: z
    .string()
    .optional()
    .default('')
    .transform((value) => value || ''),
  briefing_file: z
    .any()
    .optional()
    .transform((file) => {
      return file || undefined
    })
    .refine(validateFileSize.check, validateFileSize.opts)
    .refine(validateFileType.check, validateFileType.opts),
  briefing_url: z
    .string()
    .optional()
    .default('')
    .transform((value) => value || ''),
  delivery_term_file: z
    .any()
    .optional()
    .transform((file) => {
      return file || undefined
    })
    .refine(validateFileSize.check, validateFileSize.opts)
    .refine(validateFileType.check, validateFileType.opts),
  delivery_term_url: z
    .string()
    .optional()
    .default('')
    .transform((value) => value || ''),
  project_images_attributes: z.array(ProjectTestImagesAttributesSchema),
  project_fields_attributes: z.array(ProjectFieldsAttributesSchema),

  // Campos somente exibicao
  accepted_budget_date: z.any().optional().nullable(),
  accepted_budget_value: z.any().optional().nullable(),
})

export type IFormSchema = z.infer<typeof FormSchema>
