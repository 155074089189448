import { useMemo } from 'react'

import { InputField } from '@/components/Form/Input/base.tsx'
import { SelectField } from '@/components/Form/select.tsx'
import { Row } from '@/components/row.tsx'
import { ProjectKind } from '@/lib/requests/project-kinds/get_project_kinds.ts'
import { ProjectDetails } from '@/lib/requests/projects/get-project-by-id.ts'
import { UserOption } from '@/lib/requests/users/get-users.ts'
import { CustomInput } from '@/pages/Projects/components/Form/components/custom-input.tsx'
import { ProjectCard } from '@/pages/Projects/components/Form/components/project-card.tsx'
import { IFormSchema } from '@/pages/Projects/components/Form/validate.ts'
import { useAppStore } from '@/store/app.store.ts'

interface DadosProjetoProps {
  readonly: boolean
  initialData: ProjectDetails | null
  users: UserOption[]
  projectKinds: ProjectKind[]
}

export function Dados({
  readonly = false,
  initialData,
  users,
  projectKinds,
}: DadosProjetoProps) {
  const isAdmin = useAppStore((store) => store.state.isAdmin)

  const projectKindOptions = useMemo(() => {
    if (isAdmin)
      return projectKinds.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }))
    if (!initialData) return

    return [
      {
        ...initialData.kind,
        label: initialData.kind?.name,
        value: initialData.kind?.id,
      },
    ]
  }, [initialData, isAdmin, projectKinds])

  const userOptions = useMemo(() => {
    if (isAdmin) return users
    if (!initialData) return []
    return [
      {
        ...initialData.user,
        label: initialData.user.name || '',
        value: initialData.user.id || '',
      },
    ]
  }, [initialData, isAdmin, users])

  return (
    <ProjectCard title={'Dados'}>
      <div className="flex w-full flex-col space-y-4">
        <Row>
          <div className="w-1/2 space-y-4">
            <CustomInput>
              <SelectField<IFormSchema>
                name={'user_id'}
                disabled={readonly}
                placeholder="Cliente"
                label={'Cliente'}
                options={userOptions}
              />
            </CustomInput>
          </div>

          <div className="w-1/2 space-y-4">
            <CustomInput>
              <InputField<IFormSchema>
                name={'title'}
                label={'Título do projeto'}
                inputProps={{
                  disabled: readonly,
                  placeholder: 'Título',
                }}
              />
            </CustomInput>
          </div>
        </Row>

        <Row>
          <div className="w-1/2">
            <CustomInput>
              <SelectField<IFormSchema>
                name={'project_kind'}
                disabled={readonly}
                label={'Tipo de projeto'}
                options={projectKindOptions || []}
                placeholder="Selecione o tipo do projeto"
              />
            </CustomInput>
          </div>
          <div className="w-1/2">
            <div />
          </div>
        </Row>
      </div>
    </ProjectCard>
  )
}
