import { api } from '@/lib/api'

import { MetaPaginate } from '../types'

export interface ProjectKindStatus {
  id: string
  value: string
  label: string
  order: number
  is_default: boolean
  actions: {
    can_delete: boolean
  }
}

export interface ProjectKind {
  id: string
  name: string
  status: ProjectKindStatus[]
  is_template: boolean
  actions: {
    can_delete: boolean
  }
}

interface ProjectKindOutput {
  data: ProjectKind[]
  meta: MetaPaginate
}

interface GetProjectKindsOptions {
  page?: number
  perPage?: number
  isTemplate?: boolean
}

export async function getProjectKinds(options: GetProjectKindsOptions) {
  const { page = 1, perPage = 15, isTemplate = undefined } = options

  const result = await api.get<ProjectKindOutput>(`/project_kinds`, {
    params: { page, per_page: perPage, is_template: isTemplate },
  })
  return result.data || []
}
