import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { z } from 'zod'

import { LoadingButton } from '@/components/Form/button'
import { InputField } from '@/components/Form/Input/base'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Form } from '@/components/ui/form'
import { GET_USERS } from '@/lib/react-query/keys'
import { createUser } from '@/lib/requests/users/create-user'
import { useUserStore } from '@/store/user.store'

const FormSchema = z.object({
  name: z.string({ required_error: 'Campo obrigatório' }),
  email: z
    .string({ required_error: 'Campo obrigatório' })
    .email({ message: 'Email inválido' }),
})

type IFormSchema = z.infer<typeof FormSchema>

export function CreateUserModal() {
  const queryClient = useQueryClient()
  const setModalStatus = useUserStore((store) => store.actions.setModalStatus)
  const newUserModal = useUserStore((store) => store.state.modals.newUser)

  const userCreation = useMutation({
    mutationFn: async (data: IFormSchema) => {
      return createUser({
        name: data.name,
        email: data.email,
      })
    },
    onSuccess: async () => {
      toast.success('Usuário criado com sucesso')
      await queryClient.invalidateQueries({
        queryKey: [GET_USERS],
      })
      setModalStatus('newUser', false)
    },
  })

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  })

  const onSubmit = useCallback(
    (data: IFormSchema) => {
      userCreation.mutateAsync(data)
    },
    [userCreation],
  )

  return (
    <Dialog
      open={newUserModal.status}
      onOpenChange={() => setModalStatus('newUser', false)}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Novo usuário</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full space-y-6"
          >
            <InputField<IFormSchema>
              name={'name'}
              label={'Nome'}
              inputProps={{
                placeholder: 'Nome',
              }}
            />

            <InputField<IFormSchema>
              name={'email'}
              label={'E-mail'}
              inputProps={{
                type: 'email',
                placeholder: 'E-mail',
              }}
            />

            <LoadingButton isLoading={userCreation.isPending}>
              <span>Salvar</span>
            </LoadingButton>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
