import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { AuthRootLayout } from '@/_layouts/auth-root-layout.tsx'
import LoginPage from '@/pages/Login'
import { NewPasswordPage } from '@/pages/NewPassword'
import { NotFoundPage } from '@/pages/NotFound'
import { ProjectKindsPage } from '@/pages/ProjectKinds'
import { ProjectsPage } from '@/pages/Projects'
import ProjectCreate from '@/pages/Projects/create'
import { ProjectDetails } from '@/pages/Projects/details'
import ProjectEdit from '@/pages/Projects/edit'
import SignoutPage from '@/pages/Signout'
import { Page } from '@/_layouts/page.tsx'
import { PrivateRoute } from '@/private-route.tsx'

export function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={'/login'}
          element={
            <PrivateRoute>
              <LoginPage />
            </PrivateRoute>
          }
        />
        <Route path={'/signout'} element={<SignoutPage />} />
        <Route path="/nova-senha" element={<NewPasswordPage />} />

        <Route
          element={
            <PrivateRoute>
              <AuthRootLayout />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={
              <Page title={'Projetos'}>
                <ProjectsPage />
              </Page>
            }
          />
          <Route
            path="/projetos/novo"
            element={
              <Page title={'Novo Projeto'}>
                <ProjectCreate />
              </Page>
            }
          />
          <Route
            path="/projetos/:id"
            element={
              <Page title={'Detalhes'}>
                <ProjectDetails />
              </Page>
            }
          />
          <Route
            path="/projetos/:id/editar"
            element={
              <Page title={'Editar'}>
                <ProjectEdit />
              </Page>
            }
          />
          <Route
            path="/tipos_projetos"
            element={
              <Page title={'Tipos de Projeto'}>
                <ProjectKindsPage />
              </Page>
            }
          />
        </Route>
        <Route
          path={'/*'}
          element={
            <Page title={'Página não encontrada'}>
              <NotFoundPage />
            </Page>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}
