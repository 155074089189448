import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useClerk, useSignIn } from '@clerk/clerk-react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { z } from 'zod'

import LoginImg from '@/assets/login.png'
import { LoadingButton } from '@/components/Form/button'
import { InputField } from '@/components/Form/Input/base'
import { Form } from '@/components/ui/form'
import { APIResponseError, parseError } from '@/lib/parse-clerk-errors'

interface IFormType {
  password: string
  email: string
}

const schema = z.object({
  email: z
    .string({ required_error: 'Campo obrigatório' })
    .email('E-mail inválido'),
  password: z.string({
    required_error: 'Campo obrigatório',
  }),
})

export default function LoginPage() {
  const { signIn } = useSignIn()
  const clerk = useClerk()
  const navigate = useNavigate()

  const loginMutation = useMutation({
    mutationFn: async (data: IFormType) => {
      if (!signIn) return

      const signinAttempt = await signIn.create({
        identifier: data.email,
        strategy: 'password',
        password: data.password,
      })

      if (signinAttempt?.status === 'complete') {
        await clerk.setActive({
          session: signinAttempt.createdSessionId,
        })
      }

      return Promise.resolve()
    },
    onError: (error: APIResponseError) => {
      const parsedClerkError = parseError(error as APIResponseError)

      form.setError('password', {
        type: 'manual',
        message:
          parsedClerkError.message || 'Ocorreu um erro ao realizar o login',
      })
    },
    onSuccess: () => {
      navigate('/')
    },
  })

  const form = useForm<IFormType>({
    resolver: zodResolver(schema),
  })

  const onSubmit = useCallback(
    async (data: IFormType) => {
      loginMutation.mutate(data)
    },
    [loginMutation],
  )

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex h-screen w-screen items-center justify-center"
      >
        <div className="flex rounded-md border">
          <div className="dark:bg-stale-900 flex h-max w-[400px] flex-col items-center justify-center gap-10 p-8">
            <img
              height={300}
              width={300}
              alt="Camila Prato Studio 3D Logo"
              src={LoginImg}
            />

            <InputField<IFormType>
              name="email"
              label="E-mail"
              inputProps={{
                placeholder: 'Seu e-mail',
              }}
            />
            <InputField<IFormType>
              name="password"
              label="Senha"
              inputProps={{
                placeholder: 'Sua senha',
                type: 'password',
              }}
            />

            <LoadingButton isLoading={loginMutation.isPending}>
              <span>Entrar</span>
            </LoadingButton>
          </div>
        </div>
      </form>
    </Form>
  )
}
