import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { InputField } from '@/components/Form/Input/base'
import { SelectField } from '@/components/Form/select'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet'
import { UserOption } from '@/lib/requests/users/get-users.ts'
import { useAppStore } from '@/store/app.store'
import { useProjectStore } from '@/store/project/store'

interface IFormSchema {
  done_deal: boolean | null
  title: string
  user_id: string
  code: string
}

const DEFAULT_VALUES = {
  done_deal: null,
  title: '',
  user_id: '',
  code: '',
}

const transformStringToBool = (queryValue: string | null) => {
  if (!queryValue) return null
  if (queryValue === 'true') return true
  if (queryValue === 'false') return false
}

interface ProjectsFiltersDrawerProps {
  users: UserOption[]
}

export default function ProjectsFiltersDrawer({
  users,
}: ProjectsFiltersDrawerProps) {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const {
    state: { isAdmin },
  } = useAppStore()
  const isOpened = useProjectStore((store) => store.state.modals.filters)
  const setModalStatus = useProjectStore(
    (store) => store.actions.setModalStatus,
  )

  const form = useForm<IFormSchema>({
    defaultValues: {
      done_deal: transformStringToBool(searchParams.get('done_deal')),
      title: searchParams.get('title') || '',
      code: searchParams.get('code') || '',
      user_id: searchParams.get('user_id') || '',
    },
  })

  const handleSubmit = useCallback(
    (data: IFormSchema) => {
      const params = new URLSearchParams(searchParams.toString())
      const existsPage = params.get('page')

      Object.keys(data).forEach((item) => {
        const value = data[item as keyof IFormSchema]

        if (value) {
          params.set(item, value.toString())
        } else {
          params.delete(item)
        }
      })
      params.delete('modal')

      if (existsPage) {
        params.set('page', '1')
      }

      navigate(`${pathname}?${params.toString()}`)
    },
    [navigate, pathname, searchParams],
  )

  const close = useCallback(() => {
    setModalStatus({ name: 'filters' }, false)
  }, [setModalStatus])

  return (
    <Sheet open={isOpened.status && isAdmin} onOpenChange={close}>
      <SheetContent>
        <div className="flex flex-col gap-4">
          <SheetHeader>
            <SheetTitle>Filtros</SheetTitle>
          </SheetHeader>

          <Form {...form}>
            <form
              className="flex flex-col gap-4"
              onSubmit={form.handleSubmit(handleSubmit)}
            >
              <InputField<IFormSchema>
                name={'title'}
                label={'Título do projeto'}
                inputProps={{
                  placeholder: 'Título do projeto',
                }}
              />

              <InputField<IFormSchema>
                name={'code'}
                label={'Código do projeto'}
                inputProps={{
                  placeholder: 'Código do projeto',
                }}
              />

              <SelectField<IFormSchema>
                name={'user_id'}
                placeholder="Cliente"
                label={'Cliente'}
                options={[{ label: 'Todos', value: null }, ...users]}
                disabled={users.length === 0}
              />

              <SelectField<IFormSchema>
                name={'done_deal'}
                label={'Negócio fechado'}
                options={[
                  { label: 'Sim', value: 'true' },
                  { label: 'Não', value: 'false' },
                  { label: 'Todos', value: null },
                ]}
                placeholder="Negócio fechado"
              />

              <div className="flex justify-end gap-2">
                <Button
                  variant={'secondary'}
                  type="button"
                  onClick={() => form.reset(DEFAULT_VALUES)}
                >
                  <span>Limpar</span>
                </Button>
                <Button type="submit">
                  <span>Buscar</span>
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </SheetContent>
    </Sheet>
  )
}
