import { useCallback, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { format, parseISO } from 'date-fns'
import { Check, Edit2, Plus, Trash, X } from 'lucide-react'
import { toast } from 'sonner'

import { LoadingContainer } from '@/components/loading-container.tsx'
import { Pagination } from '@/components/pagination'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Popover, PopoverTrigger } from '@/components/ui/popover'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { mountFileUrl } from '@/lib/mount-file-url'
import {
  GET_PROJECT_BUDGETS,
  GET_PROJECT_DETAILS,
} from '@/lib/react-query/keys'
import { getProjectBudgetsByProjectId } from '@/lib/requests/project_budgets/get-by-project-id.ts'
import {
  updateProject,
  UpdateProjectBudgetsRequest,
} from '@/lib/requests/projects/update-project'
import { useProjectStore } from '@/store/project/store'

import { DeleteItemConfirmationDialog } from './components/DeleteItemConfirmationDialog'

export function ProjectBudgetsModal() {
  const queryClient = useQueryClient()
  const setModalStatus = useProjectStore(
    (store) => store.actions.setModalStatus,
  )
  const [page, setPage] = useState(1)

  const budgetsModal = useProjectStore((store) => store.state.modals.budgets)
  const projectId = useMemo(() => {
    return budgetsModal.payload?.projectId || ''
  }, [budgetsModal.payload])

  const { mutateAsync: handleDelete, isPending: isLoadingDelete } = useMutation(
    {
      mutationFn: ({ rowId }: { rowId: string }) => {
        return updateProject<UpdateProjectBudgetsRequest>(projectId, {
          project_budgets_attributes: [{ id: rowId, _destroy: true }],
        })
      },
      onSuccess: async () => {
        toast.success('Registro removido com sucesso')
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: [GET_PROJECT_DETAILS, projectId],
          }),
          queryClient.invalidateQueries({
            queryKey: [GET_PROJECT_BUDGETS, projectId],
          }),
        ])
      },
    },
  )

  const { data, isLoading } = useQuery({
    queryKey: [GET_PROJECT_BUDGETS, projectId, page],
    queryFn: () => {
      if (!projectId) return
      return getProjectBudgetsByProjectId(projectId, { page })
    },
  })

  const close = useCallback(() => {
    setModalStatus({ name: 'budgets' }, false)
  }, [setModalStatus])

  return (
    <Dialog open={budgetsModal.status} onOpenChange={close}>
      <DialogContent className="sm:max-w-[650px]">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <span>Orçamentos</span>
            <Button
              variant={'ghost'}
              type="button"
              onClick={() => setModalStatus({ name: 'budgetForm' }, true)}
            >
              <Plus color="green" />
            </Button>
          </DialogTitle>
        </DialogHeader>

        {!data?.data?.length ? (
          <div className="my-10 w-full">
            <span className="flex items-center justify-center text-xl">
              {isLoading ? <LoadingContainer /> : 'Não há dados para exibir'}
            </span>
          </div>
        ) : (
          <Table className="w-full">
            <TableHeader>
              <TableRow>
                <TableHead>Data</TableHead>
                <TableHead>Valor</TableHead>
                <TableHead>Arquivo</TableHead>
                <TableHead>Aceito</TableHead>
                <TableHead></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data?.data?.map((d) => (
                <TableRow key={d.id}>
                  <TableCell>
                    {format(parseISO(d.date), 'dd/MM/yyyy')}
                  </TableCell>
                  <TableCell>
                    {Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(d.value)}
                  </TableCell>
                  <TableCell>
                    {d.budget_url ? (
                      <button
                        onClick={() => {
                          if (!d.budget_url) return
                          window.open(mountFileUrl(d.budget_url), '_blank')
                        }}
                        rel="noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        Ver orçamento
                      </button>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell>
                    {d.accepted ? <Check color="green" /> : <X color="red" />}
                  </TableCell>
                  <TableCell className="flex gap-2">
                    <button
                      type="button"
                      onClick={() => {
                        setModalStatus(
                          {
                            name: 'budgetForm',
                            payload: d,
                          },
                          true,
                        )
                      }}
                    >
                      <Edit2 size={16} />
                    </button>

                    <Popover>
                      <PopoverTrigger asChild>
                        <button type="button">
                          <Trash size={16} color="red" />
                        </button>
                      </PopoverTrigger>
                      <DeleteItemConfirmationDialog
                        isLoading={isLoadingDelete}
                        onConfirm={async () => {
                          await handleDelete({ rowId: d.id })
                        }}
                      />
                    </Popover>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        {Number(data?.meta.total_pages || 0) > 1 && (
          <Pagination
            page={page}
            totalPages={data?.meta.total_pages || 0}
            setPage={setPage}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
