import { ReactNode, useCallback, useEffect } from 'react'
import { FieldValues, Path, useFormContext } from 'react-hook-form'
import { DownloadCloud, FileArchive, FileIcon, Upload, X } from 'lucide-react'
import mime from 'mime'

import { mountFileUrl } from '@/lib/mount-file-url'
import { cn } from '@/lib/utils'

import { Button } from './ui/button'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from './ui/form'
import { InputProps } from './ui/input'
import { Tooltip } from './tooltip'

type Size = 'small' | 'default'

interface UploadFileInputProps<T extends FieldValues> {
  name: Path<T>
  label?: string
  url?: string
  readonly?: boolean
  onRemove?: () => void
  className?: string
  inputProps?: InputProps
  allowPreview?: boolean
  size?: Size
}

interface PreviewProps {
  url: string
  size?: Size
  label?: string
}

function RenderPreview({
  children,
  label = '',
}: {
  children: ReactNode
  label?: string
}) {
  return <Tooltip label={`Visualizar ${label}`}>{children}</Tooltip>
}

function RenderExternalLinkPreview({ url, size, label }: PreviewProps) {
  return (
    <RenderPreview label={label}>
      <DownloadCloud
        onClick={() => window.open(url, '_blank')}
        className={cn('h-12 w-12', {
          'h-10 w-10': size === 'small',
        })}
      />
    </RenderPreview>
  )
}

function RenderPDFPreview({ url, size, label }: PreviewProps) {
  console.log({ url })
  return (
    <RenderPreview label={label}>
      <FileIcon
        onClick={() => window.open(url, '_blank')}
        className={cn('h-12 w-12', {
          'h-10 w-10': size === 'small',
        })}
      />
    </RenderPreview>
  )
}

function RenderFilePreview({
  url,
  key,
  size,
  label,
}: PreviewProps & { key?: string }) {
  return (
    <RenderPreview label={label}>
      <FileArchive
        onClick={() => window.open(key ? mountFileUrl(key) : url, '_blank')}
        className={cn('h-12 w-12', {
          'h-10 w-10': size === 'small',
        })}
      />
    </RenderPreview>
  )
}

function RenderImagePreview({
  url,
  size,
  label,
  key,
}: PreviewProps & { key?: string }) {
  return (
    <RenderPreview label={label}>
      <img
        className={cn('h-36 w-36', {
          'h-24 w-24': size === 'small',
        })}
        src={url}
        alt="preview"
        onClick={() => window.open(key ? mountFileUrl(key) : url, '_blank')}
      />
    </RenderPreview>
  )
}

export function UploadFileInput<T extends FieldValues>({
  label,
  name,
  url,
  readonly,
  className = '',
  onRemove,
  inputProps,
  allowPreview = true,
  size = 'default',
}: UploadFileInputProps<T>) {
  const { control } = useFormContext<T>()

  const preview = useCallback(
    (fieldValue?: any): string => {
      if (url) return allowPreview ? mountFileUrl(url) : url
      if (fieldValue instanceof File) return URL.createObjectURL(fieldValue)
      return ''
    },
    [allowPreview, url],
  )

  const getType = useCallback(
    (fieldValue: any) => {
      if (url) return mime.getType(url)
      if (fieldValue instanceof File) return fieldValue.type
      return ''
    },
    [url],
  )

  useEffect(() => {
    document.addEventListener(
      'drop',
      (e) => {
        e.preventDefault()
      },
      false,
    )
  }, [])

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem
          className={cn('max-w-44', {
            'max-w-28': size === 'small',
          })}
        >
          <FormLabel asChild>
            <span
              className={cn(
                'block max-h-44 overflow-hidden overflow-ellipsis whitespace-nowrap',
                {
                  'max-h-28': size === 'small',
                },
              )}
            >
              {label}
            </span>
          </FormLabel>
          <FormControl>
            <>
              <input
                {...field}
                {...inputProps}
                value={undefined}
                type="file"
                className="hidden"
                id={name}
                onClick={(e) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  e.target.value = null
                }}
              />

              <div
                onDragOver={(e) => {
                  e.preventDefault()
                }}
                onDrop={(e) => {
                  field.onChange(e.dataTransfer.files[0])
                }}
                className={cn(
                  'relative flex h-44 w-44 cursor-pointer items-center justify-center rounded border-2 border-dashed',
                  className,
                  {
                    'h-28 w-28': size === 'small',
                  },
                )}
              >
                {preview(field.value) && (
                  <>
                    {getType(field.value)?.includes('image') && (
                      <RenderImagePreview
                        url={preview(field.value)}
                        size={size}
                        label={label}
                        key={url || ''}
                      />
                    )}

                    {getType(field.value)?.includes('pdf') && (
                      <RenderPDFPreview
                        url={preview(field.value)}
                        size={size}
                        label={label}
                      />
                    )}

                    {getType(field.value) &&
                      !getType(field.value)?.includes('pdf') &&
                      !getType(field.value)?.includes('image') && (
                        <RenderFilePreview
                          url={preview(field.value)}
                          size={size}
                          label={label}
                          key={url || ''}
                        />
                      )}

                    {!getType(field.value) && (
                      <RenderExternalLinkPreview
                        url={preview(field.value)}
                        label={label}
                      />
                    )}
                  </>
                )}

                {!preview(field.value) && (
                  <Button
                    type="button"
                    disabled={readonly}
                    onClick={() => {
                      const el = document.getElementById(name)
                      if (!el) return
                      el.click()
                    }}
                    size={'icon'}
                  >
                    <Upload
                      className={cn('h-6 w-6', {
                        'h-5 w-5': size === 'small',
                      })}
                    />
                  </Button>
                )}

                {!readonly && preview(field.value) && (
                  <button
                    type="button"
                    onClick={() => {
                      if (typeof onRemove === 'function') {
                        onRemove()
                      }
                    }}
                    className="absolute -right-2 -top-2 rounded-full bg-red-600"
                  >
                    <X
                      className={cn('h-6 w-6', {
                        'h-5 w-5': size === 'small',
                      })}
                      color="white"
                    />
                  </button>
                )}
              </div>
            </>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
