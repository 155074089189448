import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Plus, Search, User } from 'lucide-react'

import { Tooltip } from '@/components/tooltip'
import { Button } from '@/components/ui/button'
import { Sheet } from '@/components/ui/sheet'
import { GET_USERS } from '@/lib/react-query/keys.ts'
import { getUsers } from '@/lib/requests/users/get-users.ts'
import { useAppStore } from '@/store/app.store'
import { useProjectStore } from '@/store/project/store'
import { useUserStore } from '@/store/user.store'

import ProjectsFiltersDrawer from './components/FiltersDrawer'
import { ImagesReviewModal } from './components/ImageReviewModal'
import { CreateUserModal } from './components/NewUserModal'
import { ProjectsListTable } from './components/ProjectsListTable'
import { UpdateProjectStatusModal } from './components/UpdateProjectStatusModal'

export function ProjectsPage() {
  const navigate = useNavigate()
  const isAdmin = useAppStore((store) => store.state.isAdmin)

  const { data: users } = useQuery({
    queryKey: [GET_USERS],
    queryFn: getUsers,
    enabled: isAdmin,
    initialData: [],
  })

  const imageViewModal = useProjectStore(
    (store) => store.state.modals.imageReview,
  )

  const updateProjectStatusModal = useProjectStore(
    (store) => store.state.modals.updateStatus,
  )
  const setProjectModalStatus = useProjectStore(
    (store) => store.actions.setModalStatus,
  )
  const setUserModalStatus = useUserStore(
    (store) => store.actions.setModalStatus,
  )
  const newUserModal = useUserStore((store) => store.state.modals.newUser)

  const openFilterDrawer = useCallback(() => {
    setProjectModalStatus({ name: 'filters' }, true)
  }, [setProjectModalStatus])

  return (
    <section className="w-full max-w-6xl">
      <div className="flex w-full items-center justify-between">
        <span className="my-5 block text-left text-2xl">
          {isAdmin ? 'Todos os projetos' : 'Meus Projetos'}
        </span>
        {isAdmin && (
          <div className="flex space-x-2">
            <Button
              onClick={() => setUserModalStatus('newUser', true)}
              className="flex space-x-2"
              variant={'outline'}
            >
              <User />
              <span>Novo usuário</span>
            </Button>
            <Button
              onClick={() => navigate('/projetos/novo')}
              className="flex space-x-2"
            >
              <Plus />
              <span>Novo Projeto</span>
            </Button>
            <Tooltip label="Filtros">
              <Sheet>
                <Button size={'icon'} type="button" onClick={openFilterDrawer}>
                  <Search className="h-5 w-5" />
                </Button>
              </Sheet>
            </Tooltip>
          </div>
        )}
      </div>

      <ProjectsListTable />
      {isAdmin && <ProjectsFiltersDrawer users={users} />}
      {isAdmin && newUserModal.status && <CreateUserModal />}
      {isAdmin && updateProjectStatusModal.status && (
        <UpdateProjectStatusModal />
      )}
      {imageViewModal.status && <ImagesReviewModal />}
    </section>
  )
}
