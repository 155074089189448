import { useCallback, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { format, parseISO } from 'date-fns'
import { Check, Edit2, Hourglass, Plus, Trash } from 'lucide-react'
import { toast } from 'sonner'

import { LoadingContainer } from '@/components/loading-container.tsx'
import { Pagination } from '@/components/pagination'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Popover, PopoverTrigger } from '@/components/ui/popover'
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { mountFileUrl } from '@/lib/mount-file-url'
import { paymentMethodOptions } from '@/lib/payment-method-options'
import { GET_PROJECT_FINANCIAL } from '@/lib/react-query/keys'
import { getProjectFinancialByProjectId } from '@/lib/requests/project_financials/get-by-project-id'
import {
  updateProject,
  UpdateProjectFinancialRequest,
} from '@/lib/requests/projects/update-project'
import { useAppStore } from '@/store/app.store.ts'
import { useProjectStore } from '@/store/project/store'

import { DeleteItemConfirmationDialog } from './components/DeleteItemConfirmationDialog'

export function ProjectFinancialModal() {
  const isAdmin = useAppStore((store) => store.state.isAdmin)
  const queryClient = useQueryClient()
  const setModalStatus = useProjectStore(
    (store) => store.actions.setModalStatus,
  )
  const modalPayload = useProjectStore((store) => store.state.modals.financial)
  const projectId = useMemo(() => {
    return modalPayload.payload?.projectId || ''
  }, [modalPayload.payload])

  const financialModal = useProjectStore(
    (store) => store.state.modals.financial,
  )

  const [page, setPage] = useState(1)

  const { mutateAsync: handleDelete, isPending: isLoadingDelete } = useMutation(
    {
      mutationFn: ({ rowId }: { rowId: string }) => {
        return updateProject<UpdateProjectFinancialRequest>(projectId, {
          project_financials_attributes: [{ id: rowId, _destroy: true }],
        })
      },
      onSuccess: async () => {
        toast.success('Registro removido com sucesso')
        await queryClient.invalidateQueries({
          queryKey: [GET_PROJECT_FINANCIAL, projectId],
          exact: false,
        })
      },
    },
  )

  const { data, isLoading } = useQuery({
    queryKey: [GET_PROJECT_FINANCIAL, projectId, page],
    queryFn: () => {
      if (!projectId) return
      return getProjectFinancialByProjectId(projectId, { page })
    },
  })

  const close = useCallback(() => {
    setModalStatus({ name: 'financial' }, false)
  }, [setModalStatus])

  const getHumanizedPaymentMethod = useCallback((paymentMethod: string) => {
    return (
      paymentMethodOptions.find((item) => item.value === paymentMethod)
        ?.label || paymentMethod
    )
  }, [])

  const total = useMemo(() => {
    return (
      data?.data?.reduce((acc, curr) => {
        return acc + Number(curr.value)
      }, 0) || 0
    )
  }, [data])

  return (
    <Dialog open={financialModal.status} onOpenChange={close}>
      <DialogContent className="sm:max-w-[650px]">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <span>Extrato Financeiro</span>
            {isAdmin && (
              <Button
                variant={'ghost'}
                type="button"
                onClick={() => setModalStatus({ name: 'financialForm' }, true)}
              >
                <Plus color="green" />
              </Button>
            )}
          </DialogTitle>
        </DialogHeader>

        {!data?.data?.length ? (
          <div className="my-10 w-full">
            <span className="flex items-center justify-center text-xl">
              {isLoading ? <LoadingContainer /> : 'Não há dados para exibir'}
            </span>
          </div>
        ) : (
          <Table className="w-full">
            <TableHeader>
              <TableRow>
                <TableHead>Data</TableHead>
                <TableHead>Valor</TableHead>
                <TableHead>Forma de Pgto.</TableHead>
                <TableHead>Comprovante</TableHead>
                <TableHead>Pago</TableHead>
                {isAdmin && <TableHead />}
              </TableRow>
            </TableHeader>
            <TableBody>
              {data?.data?.map((d) => (
                <TableRow key={d.id}>
                  <TableCell>
                    {format(parseISO(d.date), 'dd/MM/yyyy')}
                  </TableCell>
                  <TableCell>
                    {Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(d.value)}
                  </TableCell>
                  <TableCell>
                    {getHumanizedPaymentMethod(d.payment_method)}
                  </TableCell>
                  <TableCell>
                    {d.receipt_url ? (
                      <button
                        onClick={() => {
                          if (!d.receipt_url) return
                          window.open(mountFileUrl(d.receipt_url), '_blank')
                        }}
                        rel="noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        Ver comprovante
                      </button>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell>
                    {d.paid ? <Check color="green" /> : <Hourglass />}
                  </TableCell>
                  {isAdmin && (
                    <TableCell className="flex gap-2">
                      <button
                        type="button"
                        onClick={() => {
                          setModalStatus(
                            {
                              name: 'financialForm',
                              payload: {
                                ...d,
                                projectId,
                              },
                            },
                            true,
                          )
                        }}
                      >
                        <Edit2 size={16} />
                      </button>

                      <Popover>
                        <PopoverTrigger asChild>
                          <button type="button">
                            <Trash size={16} color="red" />
                          </button>
                        </PopoverTrigger>
                        <DeleteItemConfirmationDialog
                          isLoading={isLoadingDelete}
                          onConfirm={async () =>
                            await handleDelete({ rowId: d.id })
                          }
                        />
                      </Popover>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter className="bg-white text-black">
              <TableRow>
                <TableCell colSpan={1}>Total</TableCell>
                <TableCell className="text-left">
                  {Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(total)}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        )}

        {Number(data?.meta.total_pages || 0) > 1 && (
          <Pagination
            page={page}
            totalPages={data?.meta.total_pages || 0}
            setPage={setPage}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
