import { useFieldArray, useFormContext } from 'react-hook-form'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { Plus } from 'lucide-react'

import ConditionalField from '@/components/Form/conditional-field.tsx'
import { Tooltip } from '@/components/tooltip.tsx'
import { Button } from '@/components/ui/button.tsx'
import { FieldRow } from '@/pages/Projects/components/FieldRow'
import { ProjectCard } from '@/pages/Projects/components/Form/components/project-card.tsx'
import { IFormSchema } from '@/pages/Projects/components/Form/validate.ts'

interface CamposCustomizadosProps {
  readonly: boolean
}

export function CamposCustomizados({ readonly }: CamposCustomizadosProps) {
  const form = useFormContext<IFormSchema>()
  const {
    fields: projectFields,
    append: appendProjectField,
    move,
  } = useFieldArray({
    control: form.control,
    keyName: 'itemId',
    name: 'project_fields_attributes',
  })
  const { project_fields_attributes = [] } = form.watch()

  return (
    <DragDropContext
      onDragEnd={(result) => {
        if (result.source && result.destination) {
          move(result.source.index, result.destination?.index)
        }
      }}
    >
      <ProjectCard title={'Campos Customizados'}>
        <div className="flow-root">
          <div className="-m-4 flex flex-wrap">
            <Droppable droppableId="droppable-custom-fields">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex h-max w-full flex-col gap-4"
                >
                  {!readonly &&
                    projectFields.map((field, index) => (
                      <ConditionalField
                        key={field.itemId}
                        conditional={!project_fields_attributes[index]._destroy}
                        renderWhenTrue={() => (
                          <Draggable draggableId={field.itemId} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                key={index}
                              >
                                <div key={field.itemId} className="m-4">
                                  <FieldRow
                                    disabled={readonly}
                                    index={index}
                                    setValue={form.setValue}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )}
                      ></ConditionalField>
                    ))}
                </div>
              )}
            </Droppable>
          </div>
        </div>
        <div className="flex w-full justify-end space-x-4">
          <Tooltip label="Adicionar campo">
            <Button
              type="button"
              size={'icon'}
              onClick={() =>
                appendProjectField({
                  kind: 'field_text',
                  name: '',
                  value: '',
                  show_to_client: false,
                })
              }
            >
              <Plus />
            </Button>
          </Tooltip>
        </div>
      </ProjectCard>
    </DragDropContext>
  )
}
