import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import {
  GET_PROJECT_DETAILS,
  GET_PROJECT_KINDS,
  GET_USERS,
} from '@/lib/react-query/keys'
import { getProjectKinds } from '@/lib/requests/project-kinds/get_project_kinds'
import { getProjectById } from '@/lib/requests/projects/get-project-by-id'
import { getUsers } from '@/lib/requests/users/get-users'
import { useAppStore } from '@/store/app.store'

import { ProjectBaseForm } from './form'

interface ProjectFormProps {
  readonly?: boolean
  projectId?: string
}

export function ProjectForm({ readonly, projectId }: ProjectFormProps) {
  const navigate = useNavigate()
  const isAdmin = useAppStore((store) => store.state.isAdmin)

  const { data: users, isFetching: isFetchingUsers } = useQuery({
    queryKey: [GET_USERS],
    queryFn: getUsers,
    enabled: isAdmin,
  })

  const {
    data: project,
    isError: fetchProjectIsError,
    isFetching: isFetchingProject,
  } = useQuery({
    queryKey: [GET_PROJECT_DETAILS, projectId || ''],
    queryFn: () => getProjectById(projectId || ''),
    enabled: !!projectId,
  })

  const { data: projectKinds, isFetching: isFetchingProjectKinds } = useQuery({
    queryKey: [GET_PROJECT_KINDS],
    queryFn: () =>
      getProjectKinds({ page: 1, perPage: 100, isTemplate: false }),
    enabled: isAdmin,
  })

  if (fetchProjectIsError) {
    navigate('/')
  }

  if (
    (!isAdmin && !readonly) ||
    isFetchingProject ||
    isFetchingUsers ||
    isFetchingProjectKinds
  )
    return <></>

  return (
    <ProjectBaseForm
      projectKinds={projectKinds?.data || []}
      users={users || []}
      projectId={projectId}
      initialData={project}
      readonly={readonly}
    />
  )
}
