import { produce } from 'immer'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface UserStoreState {
  modals: {
    newUser: { status: boolean }
  }
}

interface UserStoreActions {
  setModalStatus: (
    name: keyof UserStoreState['modals'],
    status: boolean,
    payload?: any,
  ) => void
}

interface UserStore {
  state: UserStoreState
  actions: UserStoreActions
}

const INITIAL_STATE: UserStoreState = {
  modals: {
    newUser: { status: false },
  },
}

export const useUserStore = create<UserStore>()(
  devtools((set) => {
    const setState = (fn: any) => set(produce<UserStore>(fn))

    return {
      state: INITIAL_STATE,
      actions: {
        setModalStatus: (name, status) => {
          setState(({ state }: UserStore) => {
            state.modals[name] = { status }
          })
        },
      },
    }
  }),
)
