import { api } from '@/lib/api'

export interface AcceptedBudget {
  id: string
  date: string
  value: number
  accepted: boolean
  budget_url: string
}

export enum ProjectImageStatus {
  awaiting = 'awaiting',
  approved = 'approved',
  rejected = 'rejected',
}

export interface ProjectHistory {
  id: number
  username: string
  from: string | null
  to: string
  created_at: string
}

export interface ProjectDetails {
  id: string
  done_deal?: boolean
  done_deal_at?: string | null
  signed_contract_at?: string | null
  finished_at?: string | null
  kind: {
    id: string
    name: string
  }
  delivered_docs_url: string | null
  project_images: {
    id: string
    title: string
    url: string
    comment: string
    status: ProjectImageStatus
  }[]
  received_docs_url: string | null
  status: {
    id: string
    label: string
    order: number
    project_kind: {
      id: string
      name: string
    }
  }
  title: string
  user: {
    id: string
    name: string
    email: string
  }
  contract_url: string | null
  briefing_url: string | null
  delivery_term_url: string | null
  created_at: string
  accepted_budget: AcceptedBudget | null
  project_history: ProjectHistory[]
  project_fields: {
    id: string
    name: string
    kind: 'field_text' | 'field_file' | 'field_url' | 'field_file'
    value: string
    show_to_client: boolean
  }[]
}

export async function getProjectById(id: string) {
  const [projectDataRequest, projectHistoryRequest] = await Promise.all([
    await api.get<ProjectDetails>(`/projects/${id}`),
    await api.get<ProjectDetails['project_history']>(
      `/projects/${id}/status_history`,
    ),
  ])

  const projectData = projectDataRequest.data
  const projectHistory = projectHistoryRequest.data.filter(
    (item) => item.from || item.to,
  )
  projectData.project_history = projectHistory

  return projectData
}
