import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useClerk } from '@clerk/clerk-react'
import { Info, List, LogOut } from 'lucide-react'

import SmallLogo from '@/assets/small-logo-header.png'
import { useAppStore } from '@/store/app.store'

import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './ui/dropdown-menu'
import { NavLink } from './nav-link'
import { Tooltip } from './tooltip'

export function Header() {
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useClerk()
  const isAdmin = useAppStore((store) => store.state.isAdmin)

  return (
    <header className="h-12 bg-primary">
      <div className="flex h-full items-center justify-between px-4">
        <div className="flex flex-1 items-center">
          <Link
            to={{
              ...location,
              search: location.pathname === '/' ? location.search : '',
              pathname: '/',
            }}
          >
            <img className="h-[40px]" alt="Small Logo" src={SmallLogo} />
          </Link>

          {isAdmin && (
            <nav className="ml-10 flex items-center space-x-4 lg:space-x-6">
              <NavLink to="/tipos_projetos">
                <List className="h-4 w-4" />
                Tipos de Projetos
              </NavLink>
            </nav>
          )}
        </div>

        <div className="flex h-full items-center justify-center gap-4">
          <Tooltip label="Tutorial">
            <a href={window.location.href} target="_blank" rel="noreferrer">
              <Info color="white" size={'28px'} />
            </a>
          </Tooltip>

          <DropdownMenu>
            <DropdownMenuTrigger>
              <Avatar>
                <AvatarImage src="" alt="User avatar" />
                <AvatarFallback>{user?.fullName?.[0]}</AvatarFallback>
              </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuLabel>{user?.fullName}</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={() => {
                  navigate('/signout', { replace: true })
                }}
                className="cursor-pointer"
              >
                <LogOut className="mr-2 h-4 w-4" />
                <span>Sair</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </header>
  )
}
