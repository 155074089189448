import { File as FileIcon } from 'lucide-react'

import { CheckboxField } from '@/components/Form/checkbox.tsx'
import { DatePickerForm } from '@/components/Form/datepicker.tsx'
import { InputField } from '@/components/Form/Input/base.tsx'
import { MoneyInput } from '@/components/Form/Input/money-input.tsx'
import { Row } from '@/components/row.tsx'
import { Tooltip } from '@/components/tooltip.tsx'
import { Button } from '@/components/ui/button.tsx'
import { mountFileUrl } from '@/lib/mount-file-url.ts'
import { ProjectDetails } from '@/lib/requests/projects/get-project-by-id.ts'
import { CustomInput } from '@/pages/Projects/components/Form/components/custom-input.tsx'
import { ProjectCard } from '@/pages/Projects/components/Form/components/project-card.tsx'
import { IFormSchema } from '@/pages/Projects/components/Form/validate.ts'
import { useAppStore } from '@/store/app.store.ts'

interface OrcamentoProjetoProps {
  readonly: boolean
  initialData: ProjectDetails | null
}

export function Orcamento({ readonly, initialData }: OrcamentoProjetoProps) {
  const isAdmin = useAppStore((store) => store.state.isAdmin)

  return (
    <ProjectCard title={'Orçamento'}>
      <div className="flex w-full flex-col space-y-4">
        <Row>
          <div className="w-1/2">
            <CustomInput>
              <InputField<IFormSchema>
                name={'received_docs_url'}
                label={'Link dos documentos recebidos'}
                inputProps={{
                  disabled: readonly,
                  placeholder: 'https://drive.google.com/...',
                }}
              />
            </CustomInput>
          </div>
          <div className="mt-10 w-1/2">
            {isAdmin && (
              <CheckboxField
                disabled={!!readonly}
                label="Negócio fechado"
                name="done_deal"
              />
            )}
          </div>
        </Row>

        <Row>
          <div className="w-1/2">
            <div
              className={'flex flex-row items-center justify-center space-x-2'}
            >
              <CustomInput>
                <DatePickerForm<IFormSchema>
                  label="Data de entrega do orçamento"
                  name="accepted_budget_date"
                  placeholder="Data de entrega do orçamento"
                  calendarProps={{
                    disabled: true,
                    mode: 'single',
                  }}
                />
              </CustomInput>
              {initialData?.accepted_budget?.budget_url && (
                <Tooltip label="Visualizar orçamento">
                  <Button
                    className={'mt-2'}
                    type="button"
                    variant={'ghost'}
                    size="icon"
                    onClick={() => {
                      if (!initialData?.accepted_budget?.budget_url) return
                      const url = mountFileUrl(
                        initialData.accepted_budget.budget_url,
                      )
                      window.open(url, '_blank')
                    }}
                  >
                    <FileIcon />
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="w-1/2">
            <CustomInput>
              <MoneyInput
                disabled
                name={'accepted_budget_value'}
                label={'Valor do orçamento fechado'}
                placeholder={'Valor do orçamento fechado'}
              />
            </CustomInput>
          </div>
        </Row>
        <Row>
          <div className="w-1/2">
            <CustomInput>
              <DatePickerForm<IFormSchema>
                label="Data do fechamento do negócio"
                name="done_deal_at"
                placeholder="Data do fechamento do negócio"
                calendarProps={{
                  disabled: readonly,
                  mode: 'single',
                }}
              />
            </CustomInput>
          </div>
          <div className="w-1/2">
            <div />
          </div>
        </Row>
      </div>
    </ProjectCard>
  )
}
