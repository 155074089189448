import { useCallback } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Plus } from 'lucide-react'

import { InputField } from '@/components/Form/Input/base.tsx'
import { RadioGroupFormField } from '@/components/Form/radio-group.tsx'
import { Tooltip } from '@/components/tooltip.tsx'
import { Button } from '@/components/ui/button.tsx'
import { UploadFileInput } from '@/components/upload-file.tsx'
import { ProjectImageStatus } from '@/lib/requests/projects/update-project.ts'
import { ProjectCard } from '@/pages/Projects/components/Form/components/project-card.tsx'
import { IFormSchema } from '@/pages/Projects/components/Form/validate.ts'
import { useAppStore } from '@/store/app.store.ts'

interface ImagensProps {
  readonly: boolean
}

export function Imagens({ readonly }: ImagensProps) {
  const isAdmin = useAppStore((store) => store.state.isAdmin)

  const form = useFormContext<IFormSchema>()
  const { fields, update, append } = useFieldArray({
    control: form.control,
    name: 'project_images_attributes',
  })

  const getMarginColorByStatus = useCallback((status: ProjectImageStatus) => {
    const colorByStatus = {
      [ProjectImageStatus.awaiting]: 'border-black-300',
      [ProjectImageStatus.approved]: 'border-green-300',
      [ProjectImageStatus.rejected]: 'border-red-300',
    }
    return colorByStatus[status]
  }, [])

  return (
    <ProjectCard title={'Imagens'}>
      <div className="flex w-full flex-col space-y-4">
        <div className="flow-root">
          <div className="-m-4 flex flex-wrap">
            {fields.map((field, index) => (
              <div key={field.id} className="m-4 flex flex-col items-center">
                {readonly ? (
                  <span className="block w-full text-start text-sm">
                    {field.title || `Imagem ${index + 1}`}
                  </span>
                ) : (
                  <InputField<IFormSchema>
                    label="Título"
                    name={`project_images_attributes.${index}.title`}
                  />
                )}
                <UploadFileInput<IFormSchema>
                  className={`${getMarginColorByStatus(field.status)}`}
                  label={''}
                  url={field.url}
                  readonly={readonly}
                  onRemove={() => {
                    update(index, {
                      ...field,
                      status: ProjectImageStatus.awaiting,
                      url: '',
                      file: undefined,
                      comment: '',
                      _destroy: true,
                    })
                  }}
                  inputProps={{
                    accept: 'image/*',
                    onChange: (e) => {
                      update(index, {
                        ...field,
                        url: '',
                        file: e.target?.files?.[0],
                        status: ProjectImageStatus.awaiting,
                        _destroy: false,
                      })
                    },
                  }}
                  name={`project_images_attributes.${index}.file`}
                />

                {isAdmin && (fields[index].file || fields[index].url) && (
                  <RadioGroupFormField
                    name={`project_images_attributes.${index}.status`}
                    disabled={readonly}
                    options={[
                      {
                        label: 'Aprovado',
                        value: ProjectImageStatus.approved,
                      },
                      {
                        label: 'Reprovado',
                        value: ProjectImageStatus.rejected,
                      },
                    ]}
                  />
                )}
              </div>
            ))}
          </div>
          {!readonly && (
            <div className={'mt-2 flex justify-end'}>
              <Tooltip label="Adicionar campo">
                <Button
                  type="button"
                  size={'icon'}
                  onClick={() =>
                    append({
                      url: '',
                      itemId: '',
                      title: '',
                      status: ProjectImageStatus.awaiting,
                      file: undefined,
                      _destroy: false,
                    })
                  }
                >
                  <Plus />
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </ProjectCard>
  )
}
