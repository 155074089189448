import { ReactNode } from 'react'
import { Link, LinkProps } from 'react-router-dom'

export type NavLinkProps = LinkProps & { children: ReactNode }

export function NavLink(props: NavLinkProps) {
  const { children, ...rest } = props

  return (
    <Link
      // data-current={pathname === props.href}
      className="flex items-center gap-1.5 text-sm font-medium text-white hover:text-white/40 data-[current=true]:text-foreground"
      {...rest}
    >
      {children}
    </Link>
  )
}
