import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { z } from 'zod'

import { LoadingButton } from '@/components/Form/button'
import { SelectField } from '@/components/Form/select'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Form } from '@/components/ui/form'
import { api } from '@/lib/api'
import {
  GET_PROJECT_DETAILS,
  GET_PROJECT_KIND_DETAILS,
  GET_PROJECTS,
} from '@/lib/react-query/keys'
import { getProjectKindDetails } from '@/lib/requests/project-kinds/get_project_kind_details'
import { ProjectKindItem } from '@/lib/requests/project-kinds/types'
import { getProjectById } from '@/lib/requests/projects/get-project-by-id'
import { useProjectStore } from '@/store/project/store'

const FormSchema = z.object({
  status: z.string({ required_error: 'Campo obrigatório' }),
})

type IFormSchema = z.infer<typeof FormSchema>

interface ChangeStatusFormProps {
  projectId: string | null
  currentStatus: string
  projectKind: string
  statusOptions: ProjectKindItem
}

function ChangeStatusForm({
  projectId,
  currentStatus,
  statusOptions,
}: ChangeStatusFormProps) {
  const queryClient = useQueryClient()

  const updateStatusModal = useProjectStore(
    (store) => store.state.modals.updateStatus,
  )
  const setModalStatus = useProjectStore(
    (store) => store.actions.setModalStatus,
  )
  const onClose = useCallback(() => {
    setModalStatus({ name: 'updateStatus' }, false)
  }, [setModalStatus])

  const changeProjectStatusMutation = useMutation({
    mutationFn: async (data: IFormSchema) => {
      if (!data.status || !projectId) return
      const payload = { project_kind_status_id: data.status }
      await api.patch(`/projects/${projectId}/status`, payload)
    },
    onSuccess: async () => {
      toast.success('Status atualizado com sucesso')
      await queryClient.invalidateQueries({
        queryKey: [GET_PROJECTS],
      })
      onClose()
    },
  })

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      status: currentStatus,
    },
  })

  const onSubmit = useCallback(
    (data: IFormSchema) => {
      changeProjectStatusMutation.mutate(data)
    },
    [changeProjectStatusMutation],
  )

  return (
    <Dialog open={updateStatusModal.status} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Alteração de status</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full space-y-6"
          >
            <SelectField<IFormSchema>
              name={'status'}
              label={'Status'}
              options={statusOptions.status.map((item) => ({
                label: item.label,
                value: item.id,
              }))}
            />

            <LoadingButton isLoading={changeProjectStatusMutation.isPending}>
              <span>Salvar</span>
            </LoadingButton>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export function UpdateProjectStatusModal() {
  const modalPayload = useProjectStore(
    (store) => store.state.modals.updateStatus,
  )

  const projectId = modalPayload.payload?.projectId || ''

  const { data, isFetching } = useQuery({
    queryKey: [GET_PROJECT_DETAILS, projectId],
    queryFn: () => getProjectById(projectId),
    enabled: !!projectId,
  })

  const { data: statusOptions } = useQuery({
    queryKey: [GET_PROJECT_KIND_DETAILS, data?.kind.id],
    queryFn: () => getProjectKindDetails(data?.kind.id || ''),
    enabled: !!data?.kind?.id,
  })

  if (!data || isFetching || !statusOptions) return null

  return (
    <ChangeStatusForm
      projectId={projectId}
      projectKind={data.kind.id}
      currentStatus={data.status.id}
      statusOptions={statusOptions}
    />
  )
}
