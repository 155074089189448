import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import {
  ArrowDownZA,
  ArrowUpAZ,
  Check,
  Edit3,
  Eye,
  ImageIcon,
  MoreHorizontal,
  X,
} from 'lucide-react'

import { Pagination } from '@/components/pagination'
import { Tooltip } from '@/components/tooltip'
import { Badge } from '@/components/ui/badge'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { GET_PROJECTS } from '@/lib/react-query/keys'
import { getProjects, Project } from '@/lib/requests/projects/get-projects'
import { useAppStore } from '@/store/app.store'
import { useProjectStore } from '@/store/project/store'

type SortDirections = 'asc' | 'desc' | null

const getNewDirection = (currentDirection: SortDirections) => {
  const directions = new Map<SortDirections, SortDirections>([
    ['asc', 'desc'],
    ['desc', 'asc'],
    [null, 'asc'],
  ])
  return directions.get(currentDirection)
}

export function ProjectsListTable() {
  const setModalStatus = useProjectStore(
    (store) => store.actions.setModalStatus,
  )

  const [searchParams] = useSearchParams()

  const [sortOptions, setSortOptions] = useState<
    Record<string, 'asc' | 'desc' | null>
  >({ created_at: 'desc' })

  const filterOptions = useMemo(() => {
    const doneDealFilter = searchParams.get('done_deal')
    const titleFilter = searchParams.get('title')
    const codeFilter = searchParams.get('code')
    const userIdFilter = searchParams.get('user_id')

    return {
      done_deal: doneDealFilter,
      title: titleFilter,
      code: codeFilter,
      user_id: userIdFilter,
    }
  }, [searchParams])

  const page = useMemo(() => {
    return Number(searchParams.get('page') || 1)
  }, [searchParams])

  const { data } = useQuery({
    queryKey: [GET_PROJECTS, sortOptions, filterOptions, page],
    queryFn: () => getProjects(sortOptions, filterOptions, { page }),
  })

  const navigate = useNavigate()
  const isAdmin = useAppStore((store) => store.state.isAdmin)

  const getSortTooltip = useCallback(
    (fieldName: keyof Project) => {
      const currentDirection = sortOptions[fieldName] || null

      if (currentDirection) {
        return currentDirection === 'asc' ? 'Crescente' : 'Decrescente'
      }

      return null
    },
    [sortOptions],
  )

  const defineSortField = useCallback(
    (fieldName: keyof Project) => {
      const currentDirection = sortOptions[fieldName] || null
      const newDirection = getNewDirection(currentDirection)
      setSortOptions({
        [fieldName]: newDirection || null,
      })
    },
    [sortOptions],
  )

  return (
    <>
      {!data?.data.length || data.meta.total_count === 0 ? (
        <div className="mt-10 w-full">
          <span className="flex items-center justify-center text-2xl">
            Não há projetos
          </span>
        </div>
      ) : (
        <Table className="w-full">
          <TableHeader>
            <TableRow>
              {isAdmin && (
                <TableHead>
                  <Tooltip label={getSortTooltip('created_at')}>
                    <button
                      className="flex items-center gap-1"
                      onClick={() => defineSortField('created_at')}
                    >
                      <span>Criado em</span>
                      <span>
                        {sortOptions?.created_at === 'desc' ||
                        !sortOptions?.created_at ? (
                          <ArrowDownZA size={'16px'} />
                        ) : (
                          <ArrowUpAZ size={'16px'} />
                        )}
                      </span>
                    </button>
                  </Tooltip>
                </TableHead>
              )}
              {isAdmin && (
                <TableHead>
                  <Tooltip label={getSortTooltip('code')}>
                    <button
                      className="flex items-center gap-1"
                      onClick={() => defineSortField('code')}
                    >
                      <span>Código</span>
                      <span>
                        {sortOptions?.code === 'desc' || !sortOptions?.code ? (
                          <ArrowDownZA size={'16px'} />
                        ) : (
                          <ArrowUpAZ size={'16px'} />
                        )}
                      </span>
                    </button>
                  </Tooltip>
                </TableHead>
              )}
              <TableHead>Tipo</TableHead>
              <TableHead>Título</TableHead>
              {isAdmin && <TableHead>Cliente</TableHead>}
              <TableHead>Status</TableHead>
              {isAdmin && <TableHead>Fechado</TableHead>}
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data?.data.map((row) => (
              <TableRow key={row.id}>
                {/* Criado em */}
                {isAdmin && (
                  <TableCell>
                    {row.created_at
                      ? format(new Date(row.created_at), 'dd/MM/yyyy')
                      : ''}
                  </TableCell>
                )}

                {/* Código */}
                {isAdmin && <TableCell>{row.code}</TableCell>}

                {/* Tipo */}
                <TableCell>{row.kind?.name || ''}</TableCell>

                {/* Título */}
                <TableCell>{row.title}</TableCell>

                {/* Cliente */}
                {isAdmin && <TableCell>{row.user?.name || ''}</TableCell>}
                <TableCell>
                  <Badge>{row.status.label}</Badge>
                </TableCell>

                {/* Negócio Fechado */}
                {isAdmin && (
                  <TableCell>
                    {row.done_deal ? (
                      <Check color="green" />
                    ) : (
                      <X color="red" />
                    )}
                  </TableCell>
                )}

                {/* Ações */}
                <TableCell>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <MoreHorizontal />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuLabel>Ações</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation()
                          navigate(`/projetos/${row.id}`)
                        }}
                      >
                        <Eye className="mr-2 h-4 w-4" />
                        <span>Detalhes</span>
                      </DropdownMenuItem>
                      {isAdmin && (
                        <>
                          <DropdownMenuItem
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation()
                              navigate(`/projetos/${row.id}/editar`)
                            }}
                          >
                            <Edit3 className="mr-2 h-4 w-4" />
                            <span>Editar</span>
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation()
                              setModalStatus(
                                {
                                  name: 'updateStatus',
                                  payload: { projectId: row.id },
                                },
                                true,
                              )
                            }}
                          >
                            <Edit3 className="mr-2 h-4 w-4" />
                            <span>Alterar status</span>
                          </DropdownMenuItem>
                        </>
                      )}
                      {row.need_image_approval && (
                        <DropdownMenuItem
                          className="cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation()
                            setModalStatus(
                              {
                                name: 'imageReview',
                                payload: { projectId: row.id },
                              },
                              true,
                            )
                            // openModal('testImagesReview')
                          }}
                        >
                          <ImageIcon className="mr-2 h-4 w-4" />
                          <span>Verificar imagens</span>
                        </DropdownMenuItem>
                      )}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {Number(data?.meta.total_pages || 0) > 1 && (
        <Pagination page={page} totalPages={data?.meta.total_pages || 0} />
      )}
    </>
  )
}
