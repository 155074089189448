import { api } from '@/lib/api'

export interface User {
  id: string
  name: string
  email: string
}

export interface UserOption extends User {
  label: string
  value: string
}

export async function getUsers(): Promise<UserOption[]> {
  const result = await api.get<User[]>('/users')
  return result.data
    ? result.data.map((user) => ({
        ...user,
        label: user.name,
        value: user.id,
      }))
    : []
}
